import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, InputAdornment, Stack, TextField, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import IconButton from '@mui/material/IconButton';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import { copyTextToClipboard } from '../../utils/clipboardUtils';
import { Controller, useFormContext } from 'react-hook-form';
import { reportDataRegistrationName } from './utils';
import { styled } from '@mui/material/styles';
import ClipboardNotification from '../ClipboardNotification';
import Button from '@mui/material/Button';
import ArrowDownTrayIcon from '../../icons/ArrowDownTrayIcon';
import { jsPDF } from 'jspdf';
import latoRegular from '../fonts/Lato-Regular-normal';
import latoSemibold from '../fonts/Lato-Semibold-bold';

const RootBox = styled(Box, { name: 'FormReportPasswordBlock' })(
  ({ theme }) => ({}),
);

const FormReportPasswordBlock = ({ disabled, language }) => {
  const { t } = useTranslation();

  const generatePDF = (code) => {
    const doc = new jsPDF();
    const reportName = t('reportFrom', { lng: language });

    doc.addFileToVFS('Lato-Regular.ttf', latoRegular);
    doc.addFont('Lato-Regular.ttf', 'Lato', 'normal');
    doc.addFileToVFS('Lato-Semibold.ttf', latoSemibold);
    doc.addFont('Lato-Semibold.ttf', 'Lato', 'bold');
    //doc.setFont("Lato")

    // Title
    doc.setFontSize(20);
    doc.setFont('Lato', 'bold');
    doc.text(reportName, 105, 20, { align: 'center' });

    // Generated Code
    doc.setFontSize(16);
    doc.setFont('Lato', 'bold');
    doc.setTextColor(0, 102, 204); // Blue for code
    doc.text(`${t('yourReportCode', { lng: language })} ${code}`, 10, 40);

    // Instructions
    doc.setTextColor(0, 0, 0); // Reset text color to black
    doc.setFont('Lato', 'normal');
    doc.setFontSize(12);
    const instructions = t('reportCodeInstructions', { lng: language });
    const wrappedInstructions = doc.splitTextToSize(instructions, 180);
    doc.text(wrappedInstructions, 10, 60, { maxWidth: 180 });

    // Add clickable link closer to instructions
    doc.setTextColor(0, 0, 255); // Blue for the link
    doc.textWithLink('https://app.safetalk.io/followup', 10, 80, {
      url: 'https://app.safetalk.io/followup',
    });

    // Save PDF
    doc.save(`${reportName}.pdf`);
  };

  return (
    <RootBox>
      <Controller
        name={reportDataRegistrationName('password')}
        render={({ field: { value } }) => (
          <TextField
            fullWidth
            hiddenLabel
            variant="filled"
            disabled={disabled}
            defaultValue={value}
            InputProps={{
              readOnly: true,
              disableUnderline: true,
              sx: {
                typography: 'h5',
              },
              endAdornment: (
                <InputAdornment position="end">
                  <Button
                    variant="contained"
                    color="primary"
                    size="medium"
                    startIcon={<ArrowDownTrayIcon />}
                    onClick={() => generatePDF(value)}
                  >
                    {t('download', { lng: language })}
                  </Button>
                </InputAdornment>
              ),
            }}
            InputLabelProps={{ shrink: false }}
            sx={{ flex: '1 1 auto' }}
          />
        )}
      />
    </RootBox>
  );
};

FormReportPasswordBlock.propTypes = {
  disabled: PropTypes.bool,
  language: PropTypes.string.isRequired,
};

FormReportPasswordBlock.defaultProps = {};

export default FormReportPasswordBlock;
