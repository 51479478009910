import React from 'react';
import PropTypes from 'prop-types';
import { Box, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import {
  answerRegistrationName,
  getAnswersError,
  getBlockTemplate,
} from './utils';

const RootBox = styled(Box, { name: 'FormTextFieldBlock' })(
  ({ theme }) => ({}),
);

const FormTextFieldBlock = ({ blockIndex, language, mode, ...rest }) => {
  const { t } = useTranslation();
  const { register, getValues, formState } = useFormContext();
  const blockTemplate = getBlockTemplate(getValues, blockIndex);
  const isMultiline =
    blockTemplate.multiline || blockTemplate.type === 'multilineTextField'; // multilineTextField is a legacy check
  const errorText = getAnswersError(formState, blockTemplate.key)?.message;

  return (
    <RootBox {...rest}>
      <TextField
        fullWidth
        disabled={mode === 'edit'}
        multiline={isMultiline}
        variant="outlined"
        maxRows={isMultiline ? 10 : 1}
        hiddenLabel
        defaultValue=""
        error={Boolean(errorText)}
        helperText={Boolean(errorText) ? t(errorText, { lng: language }) : ''}
        sx={{
          '& .MuiFormHelperText-root': {
            position: 'absolute',
            top: '100%',
          },
          ...(isMultiline && {
            '& .MuiInputBase-root': {
              height: '14.275rem',
              //height: "100%",
              alignItems: 'initial',
            },
          }),
        }}
        {...register(answerRegistrationName(blockTemplate.key), {
          required:
            mode === 'view' && blockTemplate.required && 'requiredFieldError',
        })}
      />
    </RootBox>
  );
};

FormTextFieldBlock.propTypes = {
  blockIndex: PropTypes.number.isRequired,
  language: PropTypes.string.isRequired,
  mode: PropTypes.oneOf(['view', 'edit']),
};

FormTextFieldBlock.defaultProps = {
  mode: 'view',
};

export default FormTextFieldBlock;
